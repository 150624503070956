import * as React from 'react'
import * as css from './search.module.css'
import DefaultLayout from 'layouts/Default'
import { useGlobal } from 'components/common/GlobalContext'
import PostPreview from 'components/common/PostPreview'
import { go } from 'fuzzysort'

const Results = ({ query }) => {
  const { allPosts } = useGlobal()

  const allPosts_str = allPosts.map(({ body, ...post }) => ({
    body_str: body
      .flatMap(({ children }) => children.flatMap(({ text }) => text))
      .join(' '),
    ...post
  }))

  const results = go(query, allPosts_str, {
    keys: ['title', 'body_str'],
    threshold: -175000,
    limit: 20,
  })?.map(r => r.obj)

  return (
    <section className={css.section}>
      <h2>Found {results.length === 20 && 'the top'} {results.length} result{results.length !== 1 && 's'}</h2>

      {!!results?.length && (
        <ul className={css.list}>
          {results.map((result, key) => (
            <PostPreview post={result} key={key} />
          ))}
        </ul>
      )}
    </section>
  )
}

const SearchPg = ({ location }) => {
  const params = new URLSearchParams(location.search)
  const query = params.get`query`

  return (
    <DefaultLayout className="container section-padding side-padding">
      <h1 className="h1">Results for "{query}"</h1>

      <Results query={query} location={location} />
    </DefaultLayout>
  )
}

export default SearchPg
