import * as React from 'react'
import * as css from './DateCategory.module.css'
import { Link } from 'gatsby'
import clsx from 'clsx'

/* eslint-disable react/jsx-no-comment-textnodes */

const DateCategory = ({ post, className }) => {
  const hasCategory = post.category?.length > 0

  return (
    <div className={clsx(className, css.root)}>
      <time dateTime={post.publishDate}>{post.formattedPublishDate}</time>

      {hasCategory && <>
        <span className="desktop-and-up">//</span>

        <div>
          {post.category?.map(({ title }, key) => (
            <React.Fragment key={key}>
              <Link to="#" className="link-underline">
                {title}
              </Link>
              {key !== post.category.length - 1 && ' / '}
            </React.Fragment>
          ))}
        </div>
      </>}
    </div>
  )
}

export default DateCategory
