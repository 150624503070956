import * as React from 'react'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

const Img = ({ image, alt, ...props }) => {
	if (!image?.asset) return null

	return (
		<GatsbyImage
			image={getImage(image.asset)}
			alt={alt || ''}
			{...props}
		/>
	)
}

export default Img
