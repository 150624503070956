import * as React from 'react'
import * as css from './PostPreview.module.css'
import { Link } from 'gatsby'
import DateCategory from 'components/common/DateCategory'
import clsx from 'clsx'
import Img from 'components/Img'

const PostPreview = ({ post, small }) => {
  if (!post?.seo) return null

  return (
    <article>
      <Link
        className={clsx(css.link, small && css.small)}
        to={`/${ post.seo.slug.current }`}
      >
        {post.featuredImage
          ? <Img
            className={css.img}
            image={post.featuredImage}
            alt={post.title}
          />
          : <div className={clsx(css.placeholder, css.img)} />
        }

        <div className={css.text}>
          <h3 className="h5">{post.title}</h3>
          <p className={css.desc}>{post.seo.description}</p>
          <DateCategory className={css.info} post={post} />
        </div>
      </Link>
    </article>
  )
}

export default PostPreview
